import React from "react";
import "./Footer.css";
import Logo from "./Logo";

export default function Footer() {
  return (
    <footer className="footer">
      <Logo />
      <p>@2023 SHESDEV Development. All rights reserved.</p>
    </footer>
  );
}
